<template>
  <nav class="page_nav">
    <ul class="mainmenu">
      <li class="lavel-1">
        <router-link to="/">Home</router-link>
      </li>

      <li class="lavel-1 with--drop slide--megamenu">
        <router-link to="/page/project">Project</router-link>
        <ul class="mega__width--fullscreen ">
          <div class="container">
            <div class="mega__list">
              <li class="mr-5 text-right">
                <img alt="Dignest Logo" class="project-logo-in-nav" src="../static/img/header/d.png">
              </li>
              <li class="mega--title">
                Project
                <ul>
                  <li>
                    <router-link to="/page/about"><span>About the project</span></router-link>
                  </li>
                  <li>
                    <router-link to="/working-packages"><span>Working Packages</span></router-link>
                  </li>
                  <li>
                    <router-link to="/page/project-boards"><span>Project Boards</span></router-link>
                  </li>
                </ul>
              </li>
            </div>
          </div>
        </ul>
      </li>
      <li class="lavel-1">
        <router-link to="/participants">Participants</router-link>
      </li>
      <li class="lavel-1 with--drop slide--megamenu">
        <router-link to="/activities">Activities</router-link>
        <ul class="mega__width--fullscreen">
          <div class="container">
            <div class="mega__list">
              <li></li>
              <li class="mr-5 text-right">
                <img alt="Dignest Logo" class="project-logo-in-nav" src="../static/img/header/d.png">
              </li>
              <li>
                <ul>
                  <li>
                    <router-link
                        to="/page/wp1-analysis-and-road-mapping-supporting-digitization-of-industry-in-montenegro">
                      <span>WP1- ANALYSIS AND ROAD-MAPPING: SUPPORTING DIGITIZATION OF INDUSTRY IN MONTENEGRO</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/wp2-human-capacity-building-in-digitization-of-industry4.0">
                      <span>WP2- HUMAN CAPACITY BUILDING IN DIGITIZATION OF INDUSTRY4.0</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/wp3-development-of-mne-academic-digital-innovation-hub-and-networking">
                      <span>WP3- DEVELOPMENT OF MNE ACADEMIC DIGITAL INNOVATION HUB AND NETWORKING</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/wp4-piloting-and-evaluation">
                      <span>WP4- PILOTING AND EVALUATION</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>
                    <router-link to="/page/quality-control-and-monitoring"><span>QUALITY CONTROL AND MONITORING</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/dissemination-and-exploitation"><span>DISSEMINATION AND EXPLOITATION</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/coordination-and-management"><span>COORDINATION AND MANAGEMENT</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/dignest-spring-school-2024"><span>Spring School 2024</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </div>
          </div>
        </ul>
      </li>
      <li class="lavel-1 with--drop slide--megamenu">
        <router-link to="#">Newsletters</router-link>
        <ul class="mega__width--fullscreen">
          <div class="container">
            <div class="mega__list">
              <li></li>
              <li class="mr-5 text-right">
                <img alt="Dignest Logo" class="project-logo-in-nav" src="../static/img/header/d.png">
              </li>
              <li>
                <ul>
                  <li v-for="news in newsletters" :key="news.slug">
                    <router-link
                        :to="`newsletters/${news.slug}`">
                      <span>{{ news.title }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li></li>
            </div>
          </div>
        </ul>
      </li>
      <li class="lavel-1">
        <router-link to="/downloads">Downloads</router-link>
      </li>
      <li class="lavel-1 with--drop slide--megamenu">
        <router-link to="#">National Conferences</router-link>
        <ul class="mega__width--fullscreen">
          <div class="container">
            <div class="mega__list">
              <li></li>
              <li class="mr-5 text-right">
                <img alt="Dignest Logo" class="project-logo-in-nav" src="../static/img/header/d.png">
              </li>
              <li>
                <ul>
                  <li>
                    <router-link to="/page/a">
                      <span>1st National Conference</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/2nd-national-conference:-challenges-of-digital-transformation">
                      <span>2nd National Conference</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/3rd-national-conference">
                      <span>3rd National Conference</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li></li>
            </div>
          </div>
        </ul>
      </li>
      <li class="lavel-1 erasmus-logo">
        <a target="_blank" href="https://ec.europa.eu/"><img src="../static/img/nav/ErasmusLogo.svg" alt="Erasumus"></a>
      </li>
    </ul>
  </nav>
</template>


<script>
import {newsletterCollection} from "@/firebase";

export default {
  name: 'CustomNavMenu',
  data() {
    return {
      newsletters: []
    }
  },
  firestore: {
    newsletters: newsletterCollection.where('active', '==', true).orderBy('order', 'desc')
  },
};
</script>

<style>
.erasmus-logo {
  width: 160px;
}
</style>

<style lang="scss">
.mega__list {
  display: flex;

  li {
    flex-basis: 25%;
  }
}

.project-logo-in-nav {
  max-height: 200px;
}

.mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 {
  padding-bottom: 35px !important;
}
</style>