<template>
  <div ref="scrollTo" class="main-wrapper">
    <Header/>
    <OffCanvasMobileMenu/>
    <Breadcrumb :items="items" title="Blogs"/>

    <div class="bk-blog-grid-area pt--100 pb--100 pt_md--80 pb_md--80 pb_sm--80 pt_sm--60 bg_color--5">
      <div class="container">
        <div class="row mtn--50">
          <div v-for="blog in blogs" v-bind:key="blog.slug" class="col-lg-4 col-sm-6 move-up wow blog-item-marg">
            <BlogPostThree :blog="blog" addClass="blog-standard blog-grid--modern" readMoreButton="true"/>
          </div>
        </div>
        <div class="text-center mt-5">
          <div class="basic-dark2-line-1px mb-4"></div>
          <button v-if="haveMore" class="btn load-more bg-gradient-orange" @click="onPageChange">load more</button>
        </div>
      </div>
    </div>
    <Footer/>

  </div>
</template>

<script>

import {blogsCollection} from "@/firebase";
import Header from "@/site/components/Header";

export default {
  components: {
    Header,
    OffCanvasMobileMenu: () => import('../../components/OffCanvasMobileMenu'),
    Breadcrumb: () => import('../../components/Breadcrumb'),
    BlogPostThree: () => import('../../components/BlogPostThree'),
    Footer: () => import('../../components/Footer'),
  },

  data() {
    return {
      haveMore: true,
      blogs: [],
      perPage: 6,
      last: null,
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Blogs',
          to: "/blogs"
        }
      ]
    }
  }, created() {
    blogsCollection.where('active', '==', true).orderBy('date', "desc")
        .limit(this.perPage).get()
        .then((query) => {
          if (query.size < this.perPage) {
            this.haveMore = false;
          }
          query.forEach((item) => {
            this.blogs.push(item.data())
            this.last = item
            this.$smoothScroll({
              scrollTo: this.$refs.scrollTo,
              duration: 400,
            });
          })
        })
  },
  methods: {
    onPageChange() {
      blogsCollection.where('active', '==', true).limit(this.perPage)
          .orderBy("date", "desc").startAfter(this.last)
          .get()
          .then((query) => {
            if (query.size < this.perPage) {
              this.haveMore = false;
            }
            query.forEach((item) => {
              this.blogs.push(item.data())
              this.last = item
            })
          })
    }
  },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
  },

  head() {
    return {
      title: 'Blog Grid Modern'
    }
  },
};
</script>
<style>
.blog-item-marg {
  margin-top: 100px;
}

.load-more {
  font-weight: 700;
  padding: 10px 50px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
}

.load-more:hover {
  background-color: #ca3902;
}
</style>