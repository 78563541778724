<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fullwidth mt--7 p-5 mb-5 bg-white rounded">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card class="gradient opacity-9">
            <div slot="header" class="gradient opacity-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Add new file to be downloadable</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent id="form">
                <h6 class="heading-small text-muted mb-4">File</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative=""
                                  label="Title"
                                  placeholder="Type a file name"
                                  input-classes="form-control-alternative"
                                  v-model="form.title"
                                  required
                                  type="text"
                      />
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group has-label">
                        <label class="form-control-label">File section *</label><br>
                        <b-form-select v-model="form.section" :options="options"></b-form-select><br>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <label class="form-control-label">Add file * </label><br>
                        <input type="file" alternative=""
                               label="File"
                               input-classes="form-control-alternative"
                               ref="input"
                               @change="onChange"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-button @click="formValidation(this)" class="btn btn-success">Save
                      </base-button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
          <b-modal id="modal-1" ref="greske" title="Errors">
            <p v-if="this.errors.length">
              <b>Please correct the following error(s):</b>
            <ul>
              <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
            </ul>
            </p>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase"
import {downloadsCollection, usersCollection, storageRef} from "@/firebase"
import router from "@/router"

export default {
  name: "addDownload",
  data() {
    return {
      form: {
        title: "",
        size: "",
        type: "",
        user: '',
        created: firebase.firestore.Timestamp.fromDate(new Date()),
        file: '',
        active: false,
        file_url: '',
        section:null
      },
      options: [
        { value: 'project promotion', text: 'PROJECT PROMOTION' },
        { value: 'project templates', text: 'PROJECT TEMPLATES' },
        { value: 'travel supporting documents', text: 'TRAVEL SUPPORTING DOCUMENTS' },
      ],
      errors: [],
      file_input: false
    }
  },
  methods: {
    onChange() {
      this.file_input = true
    },
    formValidation() {
      this.errors = []
      if (this.form.title.length > 100) {
        this.errors.push("Title cant have more than 500 chars")
      }
      if (this.form.title === "") {
        this.errors.push("You can't leave title empty")
      }
      if(!this.form.section){
        this.errors.push("You must add section type of the file")
      }
      if (!this.file_input) {
        this.errors.push("Did you forget to add file?")
      }
      if (this.file_input) {
        if (this.$refs.input.files[0].size > 50000000) {
          this.errors.push("File is over size limit")
        }
      }

      if (this.errors.length) {
        this.$refs['greske'].show()
        return;
      }

      this.onSubmit()
    },
    onSubmit() {
      let slugify = require("slugify")
      let timestamp = new Date()
      this.form.user = usersCollection.doc(this.$store.state.userProfile.uid);
      this.form.type = this.$refs.input.files[0]['name'].split(".").pop();
      this.form.file = slugify(this.form.title) + "-" + timestamp.getTime().toString() + "." + this.form.type;
      let uploadTask = storageRef.child('downloads/' + this.form.file).put(this.$refs['input'].files[0])
      uploadTask.on('state_changed',
          (snapshot) => {
            this.form.size = ((snapshot.totalBytes) / 1000000).toString()
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                break;
              case firebase.storage.TaskState.RUNNING:
                break;
            }
          },
          (error) => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.form.file_url = downloadURL
              downloadsCollection.add(this.form)
            });
            router.push("/admin/downloads")
          }
      );
    }
  }
}
</script>
<style>
</style>