<template>
  <div class="popup-mobile-menu popup-mobile-visiable" id="offcanvas-menu">
    <div class="mobile-menu-overlay " @click="mobiletoggleClass('removeClass', 'show-mobile-menu')"></div>
    <div class="inner bg-main-color">
      <div class="mobileheader bg-main-color">
        <div class="logo">
          <router-link to="/">
            <img src="../../site/static/img/logoWhite.png" alt="brand logo">
          </router-link>
        </div>
        <a class="mobile-close color-close-nav" href="javascript:void(0)"
           @click="mobiletoggleClass('removeClass', 'show-mobile-menu')"></a>
      </div>
      <div class="menu-content">
        <MobileNavigation/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MobileNavigation: () => import('../components/MobileNavigation'),
  },
  methods: {
    // offcanvas menu close
    mobiletoggleClass(addRemoveClass, className) {
      const el = document.querySelector('#offcanvas-menu');
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    }
  }
};
</script>
<style>
.bg-main-color {
  background-color: #282828 !important;
}

.color-close-nav:after,
.color-close-nav:before {
  background-color: #ffffff !important;
}

</style>