<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow"
               :class="type === 'dark' ? 'bg-default': ''">
            <div class="card-header border-0"
                 :class="type === 'dark' ? 'bg-transparent': ''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Downloads
                  </h3>
                </div>
                <div class="col text-right">
                  <router-link to="/admin/downloads/create">
                    <base-button type="primary" size="sm">Add new file</base-button>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
                          :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list"
                          :data="this.downloads">

                <template slot="columns">
                  <th>Title</th>
                  <th>File name</th>
                  <th> Type</th>
                  <th>Created</th>
                  <th class="text-center">Active</th>
                  <th class="text-center">#</th>
                </template>
                <template slot-scope="{row}">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ row.title }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="budget">
                    <button class="btn btn-sm btn-primary" @click="download(row.file)"><i
                        class="ni ni-cloud-download-95 align-middle"></i> Download
                    </button>
                  </td>
                  <td class="budget">
                    <div><small>Type:</small> {{ row.type }}</div>
                    <div><small>File size:</small> {{ parseFloat(row.size).toFixed(2) }} mb</div>
                  </td>
                  <td>
                    <badge class="badge-dot mr-4">
                      <div class="text-left">
                        <div><small>Created by:</small> {{ row.user.firstName }}</div>
                        <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
                      </div>
                    </badge>
                  </td>
                  <td class="text-center">
                    <div class="d-flex align-items-center">
                      <toggle-button :value="row.active"
                                     color="#2BCEA0"
                                     :sync="true"
                                     :labels="true"
                                     @change="toggleActive(row.id,row.active)"/>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <router-link :to="`downloads/edit/${row.id}`">
                        <button class="btn btn-sm btn-primary ni ni-settings">
                        </button>
                      </router-link>
                      <button class="btn btn-sm btn-danger ni ni-fat-remove ml-1" id="show-btn"
                              @click="passIdForDeletion(row)"></button>
                    </div>
                  </td>
                </template>
              </base-table>
              <b-modal hide-footer id="modal-1" ref="my-modal" title="Remove download">
                <p v-if="this.fileForDeletion" class="mt-0 mb-4">Are you sure you want to remove download file:
                  '<b>{{ this.fileForDeletion.title }}</b>'?</p>
                <button type="button" class="btn btn-danger" @click="onDelete">Remove</button>
                <b-button @click="hideModal">Cancel</b-button>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {downloadsCollection, storageRef} from "@/firebase"
import axios from 'axios';

export default {
  name: 'downloads',
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      downloads: [],
      active: null,
      fileForDeletion: null
    }
  },
  firestore: {
    downloads: downloadsCollection
  },
  methods: {
    onDelete() {
      downloadsCollection.doc(this.fileForDeletion.id).get().then((doc) => {
        if (doc.exists) {
          let file = doc.data().file
          storageRef.child('downloads/' + file).delete();
          downloadsCollection.doc(this.fileForDeletion.id).delete();
        }
      })
      this.$refs['my-modal'].hide()
    },
    toggleActive(id, active) {
      downloadsCollection.doc(id).update({active: !active})
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    download(file_name) {
      storageRef.child('downloads/' + file_name).getDownloadURL()
          .then((url) => {
            axios.get(url, {
              responseType: 'blob'
            }).then(({data}) => {
              const downloadUrl = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement('a');
              link.href = downloadUrl;
              link.setAttribute('download', file_name);
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
          });
    },
    passIdForDeletion(row) {
      this.fileForDeletion = row;
      this.$refs['my-modal'].show();
    },
  }
}
</script>

<style>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";
</style>