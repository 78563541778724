<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-full width mt--7 p-5 mb-5 bg-white rounded">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card class="gradient opacity-9">
            <div slot="header" class="gradient opacity-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">File edit</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Change the file name:</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative=""
                                  label="Title"
                                  placeholder="Type a file name"
                                  input-classes="form-control-alternative"
                                  v-model="download.title"
                                  required
                                  type="text"
                      />
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group has-label">
                        <label class="form-control-label">File section *</label><br>
                        <b-form-select v-model="download.section" :options="options"></b-form-select><br>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <label class="form-control-label">Add file </label><br>
                        <input type="file" alternative=""
                               label="File"
                               @change="onChange"
                               input-classes="form-control-alternative"
                               ref="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-button @click="formValidation()" class="btn btn-success">Save changes
                      </base-button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
          <b-modal id="modal-1" ref="greske" title="Errors">
            <p v-if="this.errors.length">
              <b>Please correct the following error(s):</b>
            <ul>
              <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
            </ul>
            </p>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase"
import router from "@/router"
import {storageRef, downloadsCollection, usersCollection} from "@/firebase"

export default {
  name: "edit",
  data() {
    return {
      download_id: this.$route.params.id,
      download: {},
      empty_file_input: false,
      errors: [],
      options: [
        { value: 'project promotion', text: 'PROJECT PROMOTION' },
        { value: 'project templates', text: 'PROJECT TEMPLATES' },
        { value: 'travel supporting documents', text: 'TRAVEL SUPPORTING DOCUMENTS' },
      ],
    }
  },
  created() {
    downloadsCollection.doc(this.download_id).get().then((doc) => {
      if (doc.exists) {
        this.download = doc.data()
      }
    });
  },
  methods: {
    onChange() {
      this.empty_file_input = true
    },
    formValidation() {
      this.errors = []
      if (this.download.title.length > 500) {
        this.errors.push("Title cant have more than 500 chars")
      }
      if (this.download.title === "") {
        this.errors.push("You can't leave title empty")
      }
      if(!this.download.section){
        this.errors.push("You must add section type of the file")
      }
      if (this.errors.length) {
        this.$refs['greske'].show()
        return;
      }
      this.onEdit()
    },
    onEdit() {
      this.download.user = usersCollection.doc(this.$store.state.userProfile.uid);
      this.download.created = firebase.firestore.Timestamp.fromDate(new Date());

      if (this.empty_file_input) {
        var slugify = require("slugify")
        var timestamp = new Date()
        let oldFile = this.download.file;
        this.download.type = this.$refs.input.files[0]['name'].split(".").pop();
        this.download.file = slugify(this.download.title + "-" + timestamp.getTime().toString() + "." + this.download.type)

        let uploadTask = storageRef.child('downloads/' + this.download.file).put(this.$refs['input'].files[0])
        uploadTask.on('state_changed',
            (snapshot) => {
              this.download.size = ((snapshot.totalBytes) / 1000000).toString()
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  break;
                case firebase.storage.TaskState.RUNNING:
                  break;
              }
            },
            (error) => {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.download.file_url = downloadURL
                storageRef.child('downloads/' + oldFile).delete()
                downloadsCollection.doc(this.download_id).update(this.download)
              });
              router.push("/admin/downloads")
            }
        );
      } else {
        if (this.download.title !== this.title) {
          downloadsCollection.doc(this.download_id).update(this.download)
          router.push("/admin/downloads")
        }
      }
    }
  }
}
</script>