<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-success opacity-8"></span>

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
      </div>
    </div>

    <div class="col-xl-12 pl-5 pr-5 order-xl-1">
      <card shadow type="secondary">
        <div slot="header" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">New Text Page</h3>
            </div>
          </div>
        </div>
        <template>
          <form @submit.prevent>
            <h6 class="heading-small text-muted mb-4">Text page info</h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <base-input alternative=""
                              label="Title *"
                              placeholder="Example"
                              input-classes="form-control-alternative"
                              v-model="textpage.title"
                  />

                  <label class="form-control-label">Page type *</label> <br>
                  <b-form-select v-model="textpage.type" :options="options"></b-form-select>
                </div>
              </div>
            </div>
            <div class="pl-lg-4 mt-3 mb-3" v-if="textpage.type === 'meeting'">
              <div class="row">
                <div class="col-lg-12">
                  <label class="form-control-label">Meeting Type</label> <br>
                  <b-form-select v-model="meetingSelected" :options="meetingOptions"></b-form-select>
                </div>
              </div>
            </div>
            <div class="pl-lg-4 mt-3 mb-3" v-if="textpage.type === 'meeting'">
              <div class="row">
                <div class="col-lg-12">
                  <base-input alternative=""
                              label="Order *"
                              placeholder="10"
                              input-classes="form-control-alternative"
                              type="number"
                              v-model="textpage.order"
                  />
                  <small>Enter order number for your meeting, higher number will be on top oh the list. </small>
                </div>
              </div>
            </div>
            <div class="pl-lg-4 mt-3 mb-3">
              <div class="row">
                <div class="col-lg-12">
                  <label class="form-control-label">Gallery</label> <br>
                  <b-form-select v-model="selectedGallery" :options="galleriesOptions"></b-form-select>
                  <small>Choose connected gallery for this page. If there is not one u can create it
                    <router-link to="/admin/galleries/create">here</router-link>
                    . This field is optional. </small>
                </div>
              </div>
            </div>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group has-label">
                    <label class="form-control-label">Files</label><br>
                    <div class="dropbox">
                          <span>
                            <p class="p-class"> Drag your files here to upload or enter them manually by clicking the box: </p>
                            <input type="file" multiple="True"
                                   ref="file"
                                   @change="handleFileInput"
                                   class="input-file">

                          </span>

                    </div>
                    <table>
                      <th>Files to upload:</th>
                      <th></th>
                      <tr :key="file.name" v-for="file in files">
                        <td class="">
                          {{ file.name }}
                        </td>
                        <td>
                          <button class="btn btn-sm btn-danger ni ni-fat-remove ml-1" @click="removeFile(index)"
                                  title="Remove"></button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4"/>
            <!-- Description -->
            <h6 class="heading-small text-muted mb-4">Main text</h6>
            <div class="pl-lg-4">
              <div class="form-group">
                <label class="form-control-label">Text *</label>

              </div>
              <wysiwyg class="wysiwyg" v-model="textpage.content"/>
            </div>
            <span class="mt-7 btn btn-primary" @click="formValidation()">Save</span>
          </form>
        </template>
      </card>
      <b-modal id="modal-1" ref="greske" title="Errors">
        <p v-if="this.errors.length">
          <b>Please correct the following error(s):</b>
        <ul>
          <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
        </ul>
        </p>
      </b-modal>

    </div>
  </div>
</template>
<script>
import firebase from "firebase"
import {galleriesCollection, textPagesCollection, usersCollection} from "@/firebase";
import {storageRef} from "@/firebase"
import router from "@/router"

export default {
  name: 'user-profile',
  components: {},
  data() {
    return {
      textpage: {
        title: '',
        slug: '',
        type: 'meeting',
        content: '',
        files: [],
        active: false,
        user: '',
        created: '',
        locked: false,
        order: 0,
        gallery: null
      },
      options: [
        {value: 'meeting', text: 'Meeting'},
        {value: 'activity', text: 'Activity'},
        {value: 'page', text: 'Page'},
        {value: 'section', text: 'Section'},
      ],
      meetingOptions: [
        {value: 'PROJECT COORDINATION MEETINGS', text: 'PROJECT COORDINATION MEETINGS'},
        {value: 'NATIONAL COORDINATION MEETINGS', text: 'NATIONAL COORDINATION MEETINGS'},
        {value: 'BILATERAL MEETINGS', text: 'BILATERAL MEETINGS'},
        {value: 'FINANCIAL MANAGEMENT MEETINGS', text: 'FINANCIAL MANAGEMENT MEETINGS'},
      ],
      meetingSelected: 'PROJECT COORDINATION MEETINGS',
      galleriesOptions: [{value: '', text: "Don't connect gallery"}],
      selectedGallery: '',
      max: 500,
      value: [],
      length: 0,
      files: [],
      errors: [],
    }
  },
  created() {
    galleriesCollection.get().then(async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.galleriesOptions.push({
          value: doc.id,
          text: doc.data().title
        })
      });
    });
  },
  methods: {
    formValidation() {
      this.isDisabled = true
      this.clicked = "Creating..."
      this.errors = []
      if (this.textpage.title.length > 250) {
        this.errors.push("Title can not have more than 250 chars.")
      }
      if (this.textpage.title === "") {
        this.errors.push("You can't leave title empty.")
      }
      if (this.textpage.type === 'meeting' && !this.textpage.order) {
        this.errors.push("You can't leave order empty")
      }
      if (this.errors.length > 0) {
        this.$refs['greske'].show()
        this.isDisabled = false
        this.clicked = "Create text page"
        return;
      }
      this.saveFiles()
    },
    save() {

      this.textpage.order = parseInt(this.textpage.order);
      let slugify = require("slugify")
      this.textpage.slug = slugify(this.textpage.title.toLowerCase())
      if (this.selectedGallery !== '') {
        this.textpage.gallery = galleriesCollection.doc(this.selectedGallery)
      } else {
        this.textpage.gallery = null
      }
      if (this.textpage.type === 'meeting') {
        this.textpage.meetingType = this.meetingSelected
      }
      this.textpage.user = usersCollection.doc(this.$store.state.userProfile.uid)
      this.textpage.active = false
      this.textpage.created = firebase.firestore.Timestamp.fromDate(new Date())
      textPagesCollection.add(this.textpage).then(() => {
        router.push('/admin/textpages');
      }).catch((error) => {
        console.error("Error adding document: ", error);
      });

    },
    async saveFiles() {
      let slugify = require("slugify")
      for (const fileIndex in this.files) {
        let timestamp = new Date()
        let fileObject = this.files[fileIndex]
        let ext = fileObject.name.split(".")[1]
        let fileNameName = slugify(fileObject.name.split(".")[0]) + "-" + timestamp.getTime().toString() + "." + ext
        try {
          let uploadTask = storageRef.child('textpages/' + fileNameName)
          await uploadTask.put(fileObject)
          let downloadURL = await uploadTask.getDownloadURL()
          this.textpage.files.push({
            fileName: fileNameName,
            fileURL: downloadURL
          })
        } catch (error) {
          console.log(error)
        }
      }
      this.save()
    },
    handleFileInput() {
      let files = this.$refs.file.files
      if (!files) return;
      ([...files]).forEach(f => {
        this.files.push(f);
      });
    },
    loadImage(event) {
      this.image.imageLoaded = true
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.image.src = e.target.result
        };
        reader.readAsDataURL(input.files[0])
      }
    },
    removeFile(fileKey) {
      this.files.splice(fileKey, 1)
    }
  }
};
</script>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.dropbox:hover {
  background: #bacec6;
}

.p-class {
  font-size: 1.2em;
  text-align: center;
  padding: 20px 0;
}

.wysiwyg {
  height: 100%;
  background-color: white;
}
</style>