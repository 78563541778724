<template>
  <div class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>
    <div
        class="breadcaump-area pt--250 pt_md--200 pt_sm--150 pb--50 pb_sm--100 bg_image--8 breadcaump-title-bar">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcaump-inner text-center">
              <div class="breadcrumb-insite">
                <div class="header mb--40 text-center">
                  <h3 class="heading heading-h3 font-32 text-white">Activities</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt--200 pb-100 brook-blog-details-area space_dec--100 pt_md--80 pt_sm--80">
      <div class="container">
        <div class="row mb--85">
          <div class="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
            <div class="blog-post-return-button">
              <button @click="back()"><i class="fa fa-arrow-left"></i> Back</button>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2">
            <div class="blog-details-wrapper">
              <article class="blog-post blog-modern-layout">
                <!-- Start Content -->
                <div class="content text-page-content basic-dark2-line-1px pb--40 mb--35">
                  <ul>
                    <li class="pt-2 pb-2" v-for="activity in activities" v-bind:key="activity.id">
                      <router-link v-if="activity.slug === 'coordination-and-management'" class="list-link"
                                   :to="`/${activity.slug}`">{{ activity.title }}
                      </router-link>
                      <router-link v-if="activity.slug !== 'coordination-and-management'" class="list-link"
                                   :to="`/page/${activity.slug}`">{{ activity.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Details -->

    <Footer/>

  </div>
</template>

<script>
import {textPagesCollection} from "@/firebase";
import Header from "@/site/components/Header";

export default {
  components: {
    Header,
    OffCanvasMobileMenu: () => import('../components/OffCanvasMobileMenu'),
    Footer: () => import('../components/Footer'),
  },
  data() {
    return {
      activities: []
    }
  },
  methods: {
    back() {
      window.history.back();
    }
  },
  firestore: {
    activities: textPagesCollection.where('type', '==', 'activity')
        .where('active', '==', true).orderBy('order', 'asc')
  },
  // created() {
  //   .get().then((result) => {
  //     result.forEach((item) => {
  //       this.activities = item.data();
  //     })
  //   });
  // },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
  }
};
</script>
<style>
.content.text-page-content {
  overflow-x: scroll;
}

.content.text-page-content::-webkit-scrollbar {
  display: none;
}

.content.text-page-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content.text-page-content a {
  color: #FF4E00 !important;
  background-color: transparent !important;
}

.content.text-page-content a:hover {
  color: #b13702 !important;
}

.content.text-page-content a:hover {
  color: #b13702 !important;
}

.bg_image--8 {
  background-image: url("../static/img/headerpages.jpg");
}
</style>