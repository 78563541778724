<template>
  <div ref="scrollTo" class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>

    <div
        class="breadcaump-area pt--250 pt_md--200 pt_sm--150 pb--50 pb_sm--100 bg_image--8 breadcaump-title-bar">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcaump-inner text-center">
              <div class="breadcrumb-insite">
                <div class="header mb--40 text-center">
                  <h3 class="heading heading-h3 font-32 text-white">{{ textPage.title }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt--200 pb-100 brook-blog-details-area space_dec--100 pt_md--80 pt_sm--80">
      <div class="container">
        <div class="row mb--85">
          <div class="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
            <div class="blog-post-return-button">
              <button @click="back()"><i class="fa fa-arrow-left"></i> Back</button>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2">
            <div class="blog-details-wrapper">
              <article class="blog-post blog-modern-layout">
                <!-- Start Content -->
                <div class="content text-page-content basic-dark2-line-1px pb--40 mb--35">
                  <div v-html="textPage.content"></div>
                  <div class="mt-5">
                    <router-link class="box-item-hover" :to="`/page/${meeting.slug}`" v-for="meeting in meetings"
                                 v-bind:key="meeting.id">
                      <div class="meeting-item" :class="meeting.meetingType">
                        <div class="m-type"><span :class="meeting.meetingType">{{ meeting.meetingType }}</span></div>
                        <div>{{ meeting.title }}</div>
                      </div>
                    </router-link>
                  </div>
                  <div class="blog-footer wow move-up mt-4" v-if="textPage.files.length > 0">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div>
                          <h6 class="heading heading-h6"> Attachments:</h6>
                          <div class="mt-2 blog-tag-list">
                            <div v-for="file in textPage.files" v-bind:key="file.fileName">
                              <a download :href="file.fileURL"
                                 target="_blank"> <i class="ni ni-cloud-download-95"></i> {{ file.fileName }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="blog-footer wow move-up mt-4" v-if="gallery.imagesUrls">
                    <div class="row" v-if="gallery.imagesUrls.length > 0">
                      <div class="col-lg-12 col-12">
                        <div>
                          <h6 class="heading heading-h6"> Gallery:</h6>
                          <div class="mt-4">
                            <div class="container m-auto myMainContainer">
                              <div class="images-wrapper row">
                                <div
                                    class="image col-12 col-md-6 col-lg-4 mt-4"
                                    v-for="(image, imageIndex) in gallery.imagesUrls"
                                    :key="imageIndex"
                                    @click="index = imageIndex"
                                ><img :src="image" alt=""></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Details -->
    <CoolLightBox v-if="gallery.imagesUrls"
                  :items="gallery.imagesUrls"
                  :index="index"
                  :effect="'fade'"
                  @close="index = null">
    </CoolLightBox>
    <Footer/>

  </div>
</template>

<script>
import {textPagesCollection} from "@/firebase";
import Header from "@/site/components/Header";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  data() {
    return {
      meetings: [],
      gallery: {
        imagesUrls: []
      },
      textPage: {
        files: []
      },
      index: null
    }
  },
  components: {
    Header,
    OffCanvasMobileMenu: () => import('../components/OffCanvasMobileMenu'),
    Footer: () => import('../components/Footer'),
    CoolLightBox,
  },
  firestore: {
    meetings: textPagesCollection.where('type', '==', 'meeting').where('active', '==', true).orderBy('order', 'desc')
  },
  methods: {
    back() {
      window.history.back();
    }
  },
  created() {
    textPagesCollection.where('slug', '==', 'coordination-and-management')
        .where('active', '==', true).get().then((result) => {
      result.forEach((item) => {
        this.textPage = item.data();
        if (this.textPage.gallery !== null && this.textPage.gallery !== undefined && this.textPage.gallery) {
          this.gallery = this.textPage.gallery.get().then((g) => {
            this.gallery = g.data();
          });
        } else {
          this.gallery = {
            imagesUrls: []
          }
        }
        this.$smoothScroll({
          scrollTo: this.$refs.scrollTo,
          duration: 400,
        });
      })
    });
  },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
  },
  computed: {
    rows() {
      return this.gallery.imagesUrls.length
    }
  },
};
</script>
<style>

.content.text-page-content {
  overflow-x: scroll;
}

.content.text-page-content ul li {
  list-style: inside;
}

.content.text-page-content::-webkit-scrollbar {
  display: none;
}

.content.text-page-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bg_image--8 {
  background-image: url("../static/img/headerpages.jpg");
}

.meeting-item {
  background: #f4763f;
  margin: 5px;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}


.meeting-item a {
  color: #ffffff !important;
}

.m-type span {
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}

.meeting-item.PROJECT.COORDINATION.MEETINGS {
  background: #f4763f;
  color: #ffffff;
}

.meeting-item.NATIONAL.COORDINATION.MEETINGS {
  background: #fdca46;
  color: #ffffff;
}

.meeting-item.BILATERAL.MEETINGS {
  background: #67b134;
  color: #ffffff;
}

.meeting-item.FINANCIAL.MANAGEMENT.MEETINGS {
  background: #503857;
  color: #ffffff;
}

.meeting-item.PROJECT.COORDINATION.MEETINGS:hover {
  background: #b5572e;
}

.meeting-item.NATIONAL.COORDINATION.MEETINGS:hover {
  background: #cba238;
}

.meeting-item.BILATERAL.MEETINGS:hover {
  background: #508a29;
}

.meeting-item.FINANCIAL.MANAGEMENT.MEETINGS:hover {
  background: #3a2640;
}

</style>