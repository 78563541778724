<template>
  <div ref="scrollTo" class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>

    <div
        class="breadcaump-area pt--250 pt_md--200 pt_sm--150 pb--50 pb_sm--100 bg_image--8 breadcaump-title-bar">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcaump-inner text-center">
              <div class="breadcrumb-insite">
                <div class="header mb--40 text-center">
                  <h3 class="heading heading-h3 font-32 text-white">Working Packages</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt--200 brook-blog-details-area space_dec--100 pt_md--80 pt_sm--80">
      <div class="container">
        <div class="row mb--85">
          <div class="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
            <div class="blog-post-return-button">
              <button @click="back()"><i class="fa fa-arrow-left"></i> Back</button>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2">
            <div class="blog-details-wrapper">
              <article class="blog-post blog-modern-layout">
                <div class="content basic-dark2-line-1px pb--40 mb--35">

                  <div class="row">
                    <div class="col-lg-3 col-md-3">
                      <router-link
                          to="/page/wp1-analysis-and-road-mapping-supporting-digitization-of-industry-in-montenegro">
                        <img src="../static/img/working-packages/Group4.png" alt=""></router-link>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <router-link to="/page/wp2-human-capacity-building-in-digitization-of-industry4.0"><img
                          src="../static/img/working-packages/Group5.png" alt=""></router-link>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <router-link to="/page/wp3-development-of-mne-academic-digital-innovation-hub-and-networking"><img
                          src="../static/img/working-packages/Group6.png" alt=""></router-link>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <router-link to="/page/wp4-piloting-and-evaluation"><img
                          src="../static/img/working-packages/Group7.png" alt=""></router-link>
                    </div>
                  </div>
                  <div class="row mt-2">

                    <div class="col-lg-3 col-md-3">
                      <router-link to="/page/dissemination-and-exploitation"><img
                          src="../static/img/working-packages/Group2.png" alt=""></router-link>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <router-link to="/page/quality-control-and-monitoring"><img class="height-img-95"
                                                                                  src="../static/img/working-packages/Group3.png"
                                                                                  alt=""></router-link>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <router-link to="/coordination-and-management"><img
                          src="../static/img/working-packages/Group1.png" alt=""></router-link>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Details -->

    <Footer/>

  </div>
</template>

<script>
import Header from "@/site/components/Header";

export default {
  components: {
    Header,
    OffCanvasMobileMenu: () => import('../components/OffCanvasMobileMenu'),
    Footer: () => import('../components/Footer'),
  },
  data() {
    return {}
  },
  methods: {
    back() {
      window.history.back();
    }
  },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
    this.$smoothScroll({
      scrollTo: this.$refs.scrollTo,
      duration: 400,
    });
  },
};
</script>
<style>
.height-img-95 {
  width: 100%;
  height: 95%;
}

.bg_image--8 {
  background-image: url("../static/img/headerpages.jpg");
}
</style>