<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-success opacity-8"></span>

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
      </div>
    </div>

    <div class="col-xl-12 pl-5 pr-5 order-xl-1">
      <card shadow type="secondary">
        <div slot="header" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">New Newsletter</h3>
            </div>
          </div>
        </div>
        <template>
          <form @submit.prevent>
            <h6 class="heading-small text-muted mb-4">Newsletter info</h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <base-input alternative=""
                              label="Title *"
                              placeholder="Example"
                              input-classes="form-control-alternative"
                              v-model="newsletter.title"
                  />
                </div>
                <div class="col-lg-12">
                  <base-input alternative=""
                              label="URL of the newsletter*"
                              placeholder="https://online.fliphtml5.com/123456789"
                              input-classes="form-control-alternative"
                              v-model="newsletter.url"
                  />
                </div>
                <div class="col-lg-12">
                  <base-input alternative=""
                              label="Order *"
                              placeholder="10"
                              input-classes="form-control-alternative"
                              type="number"
                              v-model="newsletter.order"
                  />
                  <small>Enter order number for your meeting, higher number will be on top oh the list. </small>
                </div>
              </div>
            </div>
            <hr class="my-4"/>
            <span class="mt-7 btn btn-primary" @click="formValidation()">Save</span>
          </form>
        </template>
      </card>
      <b-modal id="modal-1" ref="greske" title="Errors">
        <div v-if="this.errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
          </ul>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
import firebase from "firebase"
import {newsletterCollection, usersCollection} from "@/firebase";
import router from "@/router"

export default {
  name: 'newsletter',
  components: {},
  data() {
    return {
      newsletter: {
        title: '',
        slug: '',
        url: '',
        active: false,
        user: '',
        created: '',
        locked: false,
        order: 0,
      },
      errors: [],
    }
  },
  methods: {
    formValidation() {
      this.isDisabled = true
      this.clicked = "Creating..."
      this.errors = []
      if (this.newsletter.title.length > 250) {
        this.errors.push("Title can not have more than 250 chars.")
      }
      if (this.newsletter.title === "") {
        this.errors.push("You can't leave title empty.")
      }
      if (this.newsletter.url === "") {
        this.errors.push("You can't leave url empty.")
      }
      if (this.errors.length > 0) {
        this.$refs['greske'].show()
        this.isDisabled = false
        this.clicked = "Create text page"
        return;
      }
      this.save()
    },
    save() {

      this.newsletter.order = parseInt(this.newsletter.order);
      let slugify = require("slugify")
      this.newsletter.slug = slugify(this.newsletter.title.toLowerCase())
      this.newsletter.user = usersCollection.doc(this.$store.state.userProfile.uid)
      this.newsletter.active = false
      this.newsletter.created = firebase.firestore.Timestamp.fromDate(new Date())
      newsletterCollection.add(this.newsletter).then(() => {
        router.push('/admin/newsletter');
      }).catch((error) => {
        console.error("Error adding document: ", error);
      });

    }
  }
};
</script>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.dropbox:hover {
  background: #bacec6;
}

.p-class {
  font-size: 1.2em;
  text-align: center;
  padding: 20px 0;
}

.wysiwyg {
  height: 100%;
  background-color: white;
}
</style>