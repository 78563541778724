<template>
  <div ref="scrollTo" class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>

    <div
        class="breadcaump-area pt--260 pt_md--200 pt_sm--150 pb--160 pb_sm--100  breadcaump-title-bar breadcaump-title-white default-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcaump-inner text-center">
              <div class="breadcrumb-insite">
                <div class="header mb--40 text-center">
                  <h3 class="heading heading-h3 font-32 text-white">{{ blog.title }}</h3>
                  <div class="post-meta mt--20 text-white bk-hover align-items-center d-flex justify-content-center">
                    <div class="post-date">{{ blog.date }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Blog Details -->
    <div class="brook-blog-details-area space_dec--100 pt_md--80 pt_sm--80">
      <div class="container">
        <div class="row mb--30">

          <div class="col-lg-12">
            <div class="blog-modern-layout">
              <div class="thumbnail mb--60">
                <img class="w-100" :src="blog.imageUrl" alt="Multipurpose">
              </div>
            </div>
          </div>

          <div class="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
            <div class="blog-post-return-button">
              <button @click="back()"><i class="fa fa-arrow-left"></i> Back</button>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2">
            <div class="blog-details-wrapper">
              <article class="blog-post blog-modern-layout">
                <!-- Start Content -->
                <div class="content text-page-content basic-dark2-line-1px pb--40 mb--35">
                  <div v-html="blog.content"></div>

                  <div class="blog-footer wow move-up mt-4" v-if="blog.files.length > 0">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div>
                          <h6 class="heading heading-h6">Article Files:</h6>
                          <div class="mt-2 blog-tag-list">
                            <div v-for="file in blog.files" v-bind:key="file.fileName">
                              <a download :href="file.fileURL"
                                 target="_blank"> <i class="ni ni-cloud-download-95"></i> {{ file.fileName }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blog-footer wow move-up mt-4" v-if="blogCats.length > 0">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div>
                          <h6 class="heading heading-h6">Article Files:</h6>
                          <div class="mt-2 blog-tag-list">
                            <div v-for="cat in this.blogCats" v-bind:key="cat">
                              <h6 class="heading heading-h6 blog-file-category ">{{ cat }}</h6>
                              <div v-for="file in blogFiles" v-bind:key="file.name">
                                <a v-if="file.type === cat" :download="file.name" :href="file.fileUrl"
                                   class="blog-file-category-file"
                                   target="_blank"> <i class="ni ni-cloud-download-95"></i> {{ file.name }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blog-footer wow move-up mt-4" v-if="blog.relatedLink">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div>
                          <h6 class="heading heading-h6">Related link:</h6>
                          <div class="mt-2 blog-tag-list">
                            <a download :href="blog.relatedLink"
                               target="_blank"> <i class="ni ni-compass-04 align-middle"></i> {{ blog.relatedLink }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Start Footer -->
                <div class="blog-footer wow move-up">
                  <div class="row">
                    <div class="col-lg-12 col-12">
                      <div class="post-tag d-flex align-items-center justify-content-center">
                        <h6 class="heading heading-h6">TAGS:</h6>
                        <div class="blog-tag-list pl--5">
                          <router-link v-for="tag in blog.tags" v-bind:key="tag" :to="`/blogs/tag/${tag}`">
                            {{ tag }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Details -->
    <div v-if="related.length > 0" class="bk-carousel-slider ptb--50 ptb-md--80 ptb-sm--60 swiper-arrow-hover">
      <div class="container">
        <div class="row pb--50">
          <div class="col-lg-12 col-12">
            <div class="post-tag d-flex align-items-center justify-content-center">
              <h6 class="heading heading-h6">Related News</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="brook-element-carousel">
              <swiper :options="swiperOption">
                <!-- Start Single Carousel -->
                <div v-for="blog in related" v-bind:key="blog.id" class="carousle-slider swiper-slide">
                  <div class="thumb">
                    <img :src="blog.imageUrl" height="200" alt="Image">
                  </div>
                  <div class="content">
                    <div class="spacing"></div>
                    <h5>
                      <router-link :to="`/blogs/${blog.slug}`" class="related-link">{{
                          blog.title | str_limit(50)
                        }}
                      </router-link>
                    </h5>
                    <p>{{ blog.brief | str_limit(100) }}</p>
                  </div>
                </div>
                <!-- End Single Carousel -->
              </swiper>

              <!-- Add Pagination -->
              <div class="swiper-pagination swiper-dots-bottom"></div>

              <!-- slider arrow navigation -->
              <div class="ht-swiper-button ht-swiper-button-prev swiper-arrow-center">
                <i class="fas fa-angle-left"></i>
              </div>
              <div class="ht-swiper-button ht-swiper-button-next swiper-arrow-center">
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>

  </div>
</template>

<script>
import {blogsCollection, blogsFilesCollection} from "@/firebase";
import '../../assets/scss/style.scss'
import Header from "@/site/components/Header";
import "../../plugins/vue-awesome-swiper";

export default {
  components: {
    Header,
    OffCanvasMobileMenu: () => import('../../components/OffCanvasMobileMenu'),
    Footer: () => import('../../components/Footer'),
  },
  data() {
    return {
      slug: this.$route.params.slug,
      related: [],
      blogCats: [],
      blogFiles: [],
      blog: {
        files: [],
        tags: []
      },
      swiperOption: {
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.ht-swiper-button-next',
          prevEl: '.ht-swiper-button-prev'
        },
        // Responsive breakpoints
        breakpoints: {
          992: {
            slidesPerView: 3
          },

          768: {
            slidesPerView: 2
          },
          300: {
            slidesPerView: 1
          }
        }
      }
    }
  },
  methods: {
    getData(slug) {
      this.blogCats = [];
      this.blogFiles = [];
      blogsCollection.where('slug', '==', slug).get().then((result) => {
        result.forEach((item) => {
          this.blog = item.data();
          if (!Object.prototype.hasOwnProperty.call(this.blogFiles, 'blogFiles')) {
            for (const f in this.blog.blogFiles) {
              blogsFilesCollection.doc(this.blog.blogFiles[f].id).get().then((result) => {
                let data = result.data();
                this.blogFiles.push(data);
                if (!this.blogCats.includes(data.type)) {
                  this.blogCats.push(data.type);
                }
              });
            }
          }

          this.$smoothScroll({
            scrollTo: this.$refs.scrollTo,
            duration: 400,
          });
          if (this.blog.tags.length > 0) {
            this.related = [];
            blogsCollection.where('tags', 'array-contains-any', this.blog.tags)
                .where('active', '==', true)
                .orderBy('created', "desc")
                .limit(6).get().then((query) => {
              query.forEach((item) => {
                if (this.blog.slug !== item.data().slug) {
                  this.related.push(item.data())
                }
              })
            })
          }
        })
      });
    },
    back() {
      window.history.back();
    }
  },
  watch: {
    '$route.params.slug': function () {
      this.getData(this.$route.params.slug);
    }
  },
  created() {
    this.getData(this.$route.params.slug);
  },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
  },
  head() {
    return {
      title: 'Blog Details'
    }
  },
};
</script>
<style>
.default-gray {
  background-color: #282828;
}

.related-link {
  color: #282828;
}

.related-link:hover {
  color: #FF4E00;
}

.content.text-page-content {
  font-family: "Open Sans", sans-serif;
}

.content.text-page-content a {
  color: #FF4E00 !important;
  background-color: transparent !important;
}

.content.text-page-content a:hover {
  color: #b13702 !important;
}

.blog-file-category {
  padding-left: 15px;
  padding-top: 10px;
}

.blog-file-category-file {
  padding-left: 35px !important;
  display: block;
}

</style>

<style lang="scss">
@import "../../assets/scss/default/variables";
@import "../../assets/scss/elements/carousel-slider";
</style>