<template>
  <footer
      class="page-footer bg_color--3 pl--150 pr--150 pl_lp--40 pr_lp--40 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--20 pr_sm--20 pl_mobile--20 pr_mobile--20">
    <div class="bk-footer-inner pt--75 pb--75 pt_sm--80 pb_sm--40 pt_md--80 pb_md--40">
      <div class="row">
        <div class="col-lg-6 col-xl-6">
          <div class="bk-footer-widget logo-footer">
            <img src="../static/img/logoWhite.png">
            <p class="textUnderLogo text-muted my-3">European Commission Erasmus+ Project
              <br> 619099-EPP-1-2020-1-ME-EPPKA2-CBHE-JP <br>
              This project has been funded with support <br> from the European Commission.
            </p>
          </div>
          <div class="socialNetworks">
            <a href="https://www.instagram.com/dignest_erasmus/?hl=sr" target="_blank"><img
                src="../static/img/socialNetworks/instagram.png" alt=""></a>
            <a href="https://www.linkedin.com/company/dignest/" target="_blank"><img
                src="../static/img/socialNetworks/linkedin-logo.png" alt=""></a>
            <a href="https://www.facebook.com/dignestErasmus?mibextid=ZbWKwL" target="_blank"><img
                src="../static/img/socialNetworks/facebook.png" alt=""></a>
            <a href="https://www.youtube.com/channel/UCz0Gu39p2AibeS8lecLaQyw" target="_blank"><img style="width: 41px"
                                                                                                    src="../static/img/socialNetworks/yt.png"
                                                                                                    alt=""></a>
          </div>
        </div>

        <div class="col-lg-6 col-xl-5 offset-xl-1">
          <div class="bk-footer-widget menu--contact mt_md--30 mt_sm--30">
            <h4 class="heading heading-h4 text-white line-height-1-2">Project Coordinator</h4>
            <div class="bkseparator--35"></div>
            <div class="footer-address">
              <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-6 col-12">
                  <p class="bk_pra line-height-1-63 text-white">
                    <a href="https://www.udg.edu.me/"> UNIVERSITY OF DONJA GORICA</a>
                  </p>
                  <p class="bk_pra line-height-1-63 text-white">
                    Donja Gorica <br>
                    81000 Podgorica <br>
                    Montenegro
                  </p>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-6 col-12 mt_mobile--30">
                  <p class="bk_pra line-height-1-63 text-white">
                    Prof.dr Ivana Ognjanović
                  </p>
                  <p class="bk_pra line-height-1-63 bk-hover">
                    <a class="text-white" href="mailto:ivana.ognjanovic@udg.edu.me">ivana.ognjanovic@udg.edu.me</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Copyright Area -->
    <div class="copyright ptb--50">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="copyright-left text-md-left text-center">
            <ul class="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
              <li>
                <router-link to="/blogs">Our Blogs</router-link>
              </li>
              <li>
                <router-link to="/page/about">About the project</router-link>
              </li>
              <li>
                <router-link to="/participants">Participants</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="copyright-right text-md-right text-center">
            <p>&copy; {{ year }} <b>Dignest</b> Developed by <a target="_blank" href="https://exploring.co.me"><b>Exploring</b></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Copyright Area -->
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>

<style scoped>
.page-footer {
  background-color: #282828 !important;
}

.logo-footer img {
  height: 60px;
}

.social-share a:hover {
  color: #EC9F05 !important;
}

.socialNetworks img {
  width: 30px;
  margin: 0px 10px;
  transition: 0.2s;
  padding: 3px;
}

.socialNetworks img:hover {
  transform: scale(1.1);
}

.textUnderLogo {
  font-size: 0.8rem
}
</style>